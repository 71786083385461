var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.valorEnviado === false)?_c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"src":_vm.barImage,"mobile-breakpoint":"960","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":("to bottom, " + _vm.barColor)}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{attrs:{"item":{
        title: 'Cerrar sesión',
        icon: 'mdi-account',
        to: '/cerrar_sesion'
      }}})]},proxy:true}],null,false,3427493473),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-divider',{staticClass:"mb-1"}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"white","contain":""}},[_c('v-img',{attrs:{"src":"assets/favicon.png","max-height":"50"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.profile.title)}})],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-list',{attrs:{"expand":"","nav":""}},[_c('div'),[_c('base-item',{attrs:{"item":{
          icon: 'mdi-account-lock',
          title: 'Produccion',
          to: '/produccion',
        }}}),_c('base-item',{attrs:{"item":{
          icon: 'mdi-file-lock',
          title: 'Juzgados',
          to: '/juzgado',
        }}}),_c('base-item',{attrs:{"item":{
          icon: 'mdi-file-clock',
          title: 'Metas',
          to: '/metas',
        }}}),_c('base-item',{attrs:{"item":{
          icon: 'mdi-handcuffs',
          title: 'Delitos',
          to: '/editar_delitos',
        }}})],_c('div')],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }